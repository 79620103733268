import { TextField, Box } from '@mui/material'
import { useField, useFormikContext } from 'formik'
import React from 'react'
import { useEffect } from 'react'
import BorderedSection from "../../FormUI/BorderedSelection/BorderedSelection"

export default function DateRangePickerWrapper({
    fromProps = {},
    toProps = {},
    ...otherProps
}) {
    const [fromField, fromMeta] = useField(fromProps.name)
    const [toField, toMeta] = useField(toProps.name)

    const { setFieldValue } = useFormikContext()

    const commonConfig = {
        type: 'date',
        variant: 'outlined',
        fullWidth: true,
        InputLabelProps: {
            shrink: true
        },
        size: 'small',
    }

    const configFromPicker = {
        ...fromField,
        ...commonConfig,
        ...fromProps,
    }

    const configToPicker = {
        ...toField,
        ...commonConfig,
        ...toProps,
    }

    if (fromMeta && fromMeta.touched && fromMeta.error) {
        configFromPicker.error = true
        configFromPicker.helperText = fromMeta.error
    }

    if (toMeta && toMeta.touched && toMeta.error) {
        configToPicker.error = true
        configToPicker.helperText = toMeta.error
    }

    useEffect(() => {
        if (fromProps.value === undefined) return
        setFieldValue(fromProps.name, fromProps.value)
    }, [fromProps.value, fromProps.name, setFieldValue])

    useEffect(() => {
        if (toProps.value === undefined) return
        setFieldValue(toProps.name, toProps.value)
    }, [toProps.value, toProps.name, setFieldValue])


    return <BorderedSection style={{ margin: 0 }} {...otherProps}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '1rem' }}>
            <TextField {...configFromPicker} />
            <TextField {...configToPicker} />
        </Box>
    </BorderedSection>
}
