import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import Pagination from '../../Pagination/Pagination';
import TableBackdrop from '../../TableUI/TableBackdrop';
import CurrencyValue from '../../TableUI/CurrencyValue';
import DatetimeValue from '../../TableUI/DatetimeValue';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import TableActions from '../../TableUI/TableActions';
import { Backdrop, CircularProgress } from '@mui/material';

export default function SettlementsTable({
    data,
    pages,
    page,
    handleOnPageChange,
    handleExportCsv,
    handleExportXlsx,
    handleExportPdf,
    loading,
    fetchingData
}) {
    const { t } = useTranslation()

    return (
        <>

            <Backdrop
                sx={{ color: '#fff', fontWeight: 800, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={fetchingData}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Paper sx={{
                width: {
                    xs: 'calc(100vw - 80px)',
                    sm: 'calc(100vw - 330px)'
                }, overflow: 'hidden'
            }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell >{t('Invoice Nº')}</TableCell>
                                <TableCell align="right">{t('Deposit date')}</TableCell>
                                <TableCell align="right">{t('Total sales')}</TableCell>
                                <TableCell align="right">{t('Total deposit')}</TableCell>
                                <TableCell align="right">&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.length && !loading ? data.map((row) => (
                                <TableRow
                                    key={row.invoice_code}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.invoice_code}
                                    </TableCell>
                                    <TableCell align="right">
                                        <DatetimeValue date={row.paid_at} />
                                    </TableCell>
                                    <TableCell align="right">
                                        <CurrencyValue amount={row.withdrawable_amount} currency={row.currency} />
                                    </TableCell>
                                    <TableCell align="right">
                                        <CurrencyValue amount={row.total_paid} currency={row.currency} />
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableActions actions={[
                                            {
                                                id: `export-xlsx-${row.id}`,
                                                handleClick: () => handleExportXlsx(row.id),
                                                icon: <FileCopyIcon />,
                                                title: t('Export XLSX')
                                            },
                                            {
                                                id: `export-csv-${row.id}`,
                                                handleClick: () => handleExportCsv(row.id),
                                                icon: <FileCopyIcon />,
                                                title: t('Export CSV')
                                            },
                                            {
                                                id: `export-pdf-${row.id}`,
                                                handleClick: () => handleExportPdf(row.id, row.invoice_code),
                                                icon: <PictureAsPdfIcon />,
                                                title: t('Export PDF')
                                            }
                                        ]} />
                                    </TableCell>
                                </TableRow>
                            )) : <TableRow>
                                {
                                    loading ? <TableBackdrop open={loading} /> : <TableCell sx={{ textAlign: 'center' }} colSpan={100}>{t('No records found')}</TableCell>
                                }
                            </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            {
                data.length ? <Pagination
                    handleOnPageChange={handleOnPageChange}
                    page={page}
                    pages={pages}
                /> : ''
            }
        </>
    )
}
