import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import React from "react";
import AuthRouter from "./AuthRouter";
import Page from "../components/Pages/Page";
import Profile from "../components/Pages/Profile/Profile";
import Users from "../components/Pages/Users/Users";
import UserCreate from "../components/Pages/Users/UserCreate";
import UsersList from "../components/Pages/Users/UsersList";
import UserEdit from "../components/Pages/Users/UserEdit";
import Transactions from "../components/Pages/Transactions/Transactions";
import TransactionsList from "../components/Pages/Transactions/TransactionsList";
import Credentials from "../components/Pages/Developers/Credentials";
import Docs from "../components/Pages/Developers/Docs";
import Config from "../components/Pages/Developers/Config";
import Settlements from "../components/Pages/Settlements/Settlements";
import SettlementsList from "../components/Pages/Settlements/SettlementsLists";
import Withdrawals from "../components/Pages/Withdrawals/Withdrawals";
import WithdrawalsList from "../components/Pages/Withdrawals/WithdrawalsList";
import Balance from "../components/Pages/Balance/Balance";
import TransactionDetails from "../components/Pages/Transactions/TransactionDetails";
import Methods from "../components/Pages/Methods/Methods";
import MethodsList from "../components/Pages/Methods/MethodsList";
import PaymentLinks from "../components/Pages/PaymentLinks/PaymentLinks";
import PaymentLinksCreate from "../components/Pages/PaymentLinks/PaymentLinksCreate";
import PaymentLinksEdit from "../components/Pages/PaymentLinks/PaymentLinksEdit";
import PaymentLinksList from "../components/Pages/PaymentLinks/PaymentLinksList";
// import Documents from "../components/Pages/Documents/Documents";
import useAuth from "../hooks/useAuth";
import RequireAuth from "../components/Auth/RequireAuth";
import {
  ROLE_ADMIN,
  ROLE_OWNER,
  ROLE_ACCOUNTING,
  ROLE_DEV,
  ROLE_RO,
} from "../enums/roles";
import Cashouts from "../components/Pages/Cashouts/Cashouts";
import CashoutsBalance from "../components/Pages/Cashouts/CashoutsBalance";
import CashoutsTransactions from "../components/Pages/Cashouts/CashoutsTransactions";
import RedirectPage from "../components/RedirectPage/RedirectPage";
import UploadLogo from "../components/Pages/Profile/UploadLogo";
import EmailInvoices from "../components/Pages/EmailInvoices/EmailInvoices";
import EmailInvoicesList from "../components/Pages/EmailInvoices/EmailInvoicesList";
import EmailInvoicesCreate from "../components/Pages/EmailInvoices/EmailInvoicesCreate";
import EmailInvoicesEdit from "../components/Pages/EmailInvoices/EmailInvoicesEdit";
import EmailInvoicesUpload from "../components/Pages/EmailInvoices/EmailInvoicesUpload";
// import DocumentList from "../components/Pages/Documents/DocumentsList";
// import VersionsList from "../components/Pages/Documents/VersionsList";
import MainRouter from "./MainRouter";

export default function AppRouter() {
  const { auth } = useAuth();

  const authRoutes = AuthRouter();
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {!auth?.user && <Route path="/auth">{authRoutes}</Route>}
          <Route path="/redirect" element={<RedirectPage />} />

          <Route path="/" element={<Page />}>
            {/* <Route path="/documents" element={<Documents />}>
              <Route path="documents-list" element={<DocumentList />} />
              <Route path="versions/:documentId" element={<VersionsList />} />
            </Route> */}
            <Route element={<MainRouter />}>
              <Route path="/" element={<Balance />} />

              <Route
                element={
                  <RequireAuth
                    allowRoles={[
                      ROLE_ADMIN,
                      ROLE_OWNER,
                      ROLE_ACCOUNTING,
                      ROLE_DEV,
                      ROLE_RO,
                    ]}
                  />
                }
              >
                <Route path="/transactions" element={<Transactions />}>
                  <Route index element={<TransactionsList />} />
                  <Route
                    path="details/:transactionId"
                    element={<TransactionDetails />}
                  />
                </Route>

                <Route path="profile" element={<Profile />} />

                <Route path="profile/logo" element={<UploadLogo />} />
              </Route>

              <Route
                element={
                  <RequireAuth
                    allowRoles={[
                      ROLE_ADMIN,
                      ROLE_OWNER,
                      ROLE_ACCOUNTING,
                      ROLE_RO,
                    ]}
                  />
                }
              >
                <Route path="/withdrawals" element={<Withdrawals />}>
                  <Route index element={<WithdrawalsList />} />
                </Route>

                <Route path="/settlements" element={<Settlements />}>
                  <Route index element={<SettlementsList />} />
                </Route>

                <Route path="/cashouts" element={<Cashouts />}>
                  <Route index element={<CashoutsTransactions />} />
                  <Route path="balance" element={<CashoutsBalance />} />
                </Route>
              </Route>

              <Route
                element={
                  <RequireAuth
                    allowRoles={[ROLE_ADMIN, ROLE_OWNER, ROLE_DEV, ROLE_RO]}
                  />
                }
              >
                <Route path="/payment-methods" element={<Methods />}>
                  <Route index element={<MethodsList />} />
                </Route>

                <Route path="/email-invoices" element={<EmailInvoices />}>
                  <Route index element={<EmailInvoicesList />} />
                  <Route path="upload" element={<EmailInvoicesUpload />} />
                  <Route path="create" element={<EmailInvoicesCreate />} />
                  <Route
                    path="edit/:invoiceId"
                    element={<EmailInvoicesEdit />}
                  />
                </Route>

                <Route path="/payment-links" element={<PaymentLinks />}>
                  <Route index element={<PaymentLinksList />} />
                  <Route path="create" element={<PaymentLinksCreate />} />
                  <Route
                    path="edit/:invoiceId"
                    element={<PaymentLinksEdit />}
                  />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowRoles={[ROLE_ADMIN, ROLE_OWNER, ROLE_RO]}
                    />
                  }
                >
                  <Route path="/users" element={<Users />}>
                    <Route index element={<UsersList />} />
                    <Route path="create" element={<UserCreate />} />
                    <Route path="edit/:userId" element={<UserEdit />} />
                  </Route>
                </Route>

                <Route
                  element={
                    <RequireAuth
                      allowRoles={[ROLE_ADMIN, ROLE_OWNER, ROLE_DEV, ROLE_RO]}
                    />
                  }
                >
                  <Route path="/developers">
                    <Route path="credentials" element={<Credentials />} />
                    <Route path="config" element={<Config />} />
                    <Route path="docs" element={<Docs />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
          <Route
            path="*"
            element={<Navigate to={auth?.user ? "/" : "/auth/login"} />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
