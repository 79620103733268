import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function AlertDocuments({ isOpen = true }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClose = () => {
    navigate("documents/documents-list");
  };
  useEffect(() => {}, []);
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{t("Dear customer")}</DialogTitle>
        <DialogContent>
          <DialogContentText
            my={2}
            sx={{
              textAlign: "justify",
            }}
          >
            <Trans i18nKey="alertDocument1">
              To use our services we ask you to digitally review and approve our
              updated Terms and Conditions.
            </Trans>
          </DialogContentText>
          <DialogContentText my={2}>
            <Trans i18nKey="alertDocument2">
              In case of any doubt you can write to us at helpdesk@paygol.com.
            </Trans>
          </DialogContentText>
          <DialogContentText mt={4}>
            <Trans i18nKey="paygolMsgLine3">Paygol team</Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "2rem",
          }}
        >
          <Button onClick={handleClose} autoFocus>
            {t("I understand")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
