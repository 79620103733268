import { useEffect, useState } from 'react'
import { AppBar, Hidden, IconButton, Toolbar, Typography } from '@mui/material'
import { Box } from '@mui/system';
import Logo from './Navtabs/Logo';
import ProfileTab from './Navtabs/ProfileTab';
import MenuIcon from '@mui/icons-material/Menu';
import jwt_decode from "jwt-decode"
import LanguageSwitcher from './Navtabs/LanguageSwitcher';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../api';

export default function Navbar({
    handleDrawerToggle,
    handleChangeLanguage
}) {
    const [email, setEmail] = useState()

    const navigate = useNavigate()

    useEffect(() => {
        try {
            const user = JSON.parse(localStorage.getItem('user'))
            const { email } = jwt_decode(user?.access)
            setEmail(email)
        } catch (e) {
            navigate('/auth')
        }
    }, [navigate])

    return (
        <AppBar
            position="fixed"
            sx={{
                color: 'secondary',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                borderBottom: !/gateway/.test(BASE_URL) ? '5px solid red' : 'none'
            }}
        >
            <Toolbar>
                <IconButton
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { sm: 'none' }, color: '#fff' }}
                >
                    <MenuIcon />
                </IconButton>

                <Logo />

                <Box sx={{ flexGrow: 1 }} />

                <LanguageSwitcher handleChangeLanguage={handleChangeLanguage} />

                <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Hidden mdDown={true}>
                        {email ? <Typography>{email}</Typography> : ''}
                    </Hidden>
                    <ProfileTab email={email} />
                </Box>
            </Toolbar>
        </AppBar>
    );
}
