import PaymentLinksForm from './PaymentLinksForm'
import { Card, CardContent } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { parseErrors } from '../../../services/utils'
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import useAuth from "../../../hooks/useAuth"


export default function PaymentLinksCreate() {
  const { t } = useTranslation()

  const { setAuth } = useAuth()
  const axiosPrivate = useAxiosPrivate()

  const navigate = useNavigate()

  const handleSubmit = (values, { setFieldError }) => {
    axiosPrivate.post('/merchant/payment-links/', values)
      .then(() => {
        toast.success(t('Your Payment link has been created'));
        navigate('/payment-links')
      })
      .catch(({ response }) => {
        if (response?.status === 401) {
          localStorage.removeItem('user')
          return setAuth({})
        }

        const errors = parseErrors(response)

        if (typeof errors === 'object') {
          for (let i in errors) {
            setFieldError(errors[i].field, t(errors[i].message), false)
          }
        } else if (typeof errors === 'string') {
          toast.error(t(errors))
        } else {
          toast.error(t('Something went wrong'))
        }
      })
  }

  const handleCancel = () => {
    navigate('/payment-links')
  }

  const initialFormState = {
    title: '',
    details: '',
    amount: 0,
    currency: 'CLP',
    image: null,
  }

  const FORM_VALIDATION = Yup.object().shape({
    title: Yup.string()
      .required(t('This field is required'))
      .max(64, t('This field must contain a maximum of {{max}} characters', { max: 64 })),
    amount: Yup.number()
      .min(1, t('Price must be greater than or equal to {{min}}', { min: 1 }))
      .required(t('This field is required')),
    currency: Yup.string()
      .required(t('This field is required'))
      .test('len', t('This field must be a valid currency code'), val => val.length === 3),
    details: Yup.string()
      .max(512, t('This field must contain a maximum of {{max}} characters', { max: 512 })),
    image: Yup.string().nullable()
  })

  return (
    <>
      <Card>
        <CardContent>
          <PaymentLinksForm
            isNew={true}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            initialFormState={initialFormState}
            FORM_VALIDATION={FORM_VALIDATION}
          />
        </CardContent>
      </Card>
    </>
  )
}
