import { Button, Stack, Typography } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import UploadFileIcon from '@mui/icons-material/UploadFile'

export function Base64FileField({ ...otherProps }) {
    const { t } = useTranslation()

    const [file, setFile] = useState(null)

    const uploadFile = () => {
        const input = document.createElement('input')
        input.type = 'file'
        input.accept = 'application/pdf,image/*'
        input.onchange = (e) => {
            const file = e.target.files[0]
            const reader = new FileReader()
            reader.onloadend = () => {
                setFile(file)
                // Extract the data part of the url, which is the base64 encoded file contents
                const base64Data = reader.result.split(',')[1]
                otherProps.onChange({ target: { value: base64Data }, file: file })
            }
            reader.readAsDataURL(file)
        }
        input.click()
    }

    const error = otherProps.helperText

    return <Stack spacing={0.1}>
        <div style={{ fontSize: "0.6rem" }}>{otherProps.label}</div>
        <Button
            variant={file ? "contained" : "outlined"}
            color="primary"
            onClick={uploadFile}
            startIcon={<UploadFileIcon />}
        >
            {t(file ? "Change file" : "Choose a file")}
        </Button>
        {file && <Typography>{file.name}</Typography>}
        {error && <Typography style={{ fontSize: "0.6rem", color: "red" }}>{error}</Typography>}

    </Stack>
}