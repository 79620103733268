import { Card, CardContent, Grid, Box} from '@mui/material'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import FormSkeletonWrapper from '../../SkeletonUI/FormSkeletonWrapper/FormSkeletonWrapper'
import KeyValue from '../../TableUI/KeyValue'
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useAuth from '../../../hooks/useAuth'
import PageTitle from '../../Misc/PageTitle'

export default function TransactionDetails() {
    const { t } = useTranslation()

    const { setAuth } = useAuth()
    const axiosPrivate = useAxiosPrivate()

    const [loading, setLoading] = useState(true)

    const [transaction, setTransaction] = useState(null)

    const { transactionId } = useParams()

    useEffect(() => {
        axiosPrivate.get(`/merchant/transactions/${transactionId}/`)
            .then(({ data: { data } }) => {
                setTransaction(data)
            })
            .catch(({ response }) => {
                if (response?.status === 401) {
                    localStorage.removeItem('user')
                    return setAuth({})
                }

                toast.error(t('Something went wrong'))
            })
            .finally(() => setLoading(false))
    }, [transactionId, t, setAuth, axiosPrivate])

    return (
        <Card>
            <CardContent>
                {loading ? <FormSkeletonWrapper /> :
                    <>
                        <PageTitle title={t('Transaction details')} />

                        <Grid container spacing={2} >
                            <KeyValue
                                title={t('Transaction ID')}
                                value={transaction.transaction_id}
                                xs={12}
                                md={6}
                            />

                            <KeyValue
                                title={t('Status')}
                                value={transaction.status_ ? t(transaction.status_) : transaction.status}
                                xs={6}
                                md={2}
                            />

                            <KeyValue
                                title={t('Created at')}
                                value={new Intl.DateTimeFormat('es-CL', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(transaction.created_at))}
                                xs={6}
                                md={2}
                            />

                            <KeyValue
                                title={t('Status date')}
                                value={transaction.status_date ? new Intl.DateTimeFormat('es-CL', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(transaction.status_date)) : ''}
                                xs={6}
                                md={2}
                            />

                            <KeyValue
                                title={t('Country')}
                                value={transaction.country}
                                xs={4}
                                md={2}
                            />

                            <KeyValue
                                title={t('Price')}
                                // value={new Intl.NumberFormat('es-CL', { style: 'currency', currency: transaction.currency }).format(transaction.price)}
                                value={new Intl.NumberFormat('es-CL').format(transaction.price)}
                                xs={4}
                                md={2}
                            />

                            <KeyValue
                                title={t('Currency')}
                                value={transaction.currency}
                                xs={4}
                                md={2}
                            />

                            <KeyValue
                                title={t('Method')}
                                value={transaction.payment_method.name}
                                xs={6}
                                md={3}
                            />

                            <Box width="100%" />

                            <KeyValue
                                title={t('Customer')}
                                value={`${transaction.customer.first_name} ${transaction.customer.last_name}`}
                                xs={12}
                                md={6}
                            />

                            <KeyValue
                                title={t('Customer email')}
                                value={transaction.customer.email}
                                xs={12}
                                md={6}
                            />

                            <KeyValue
                                title={t('Product name')}
                                value={transaction.product_name}
                                xs={12}
                                md={5}
                            />

                            <KeyValue
                                title={t('Metadata')}
                                value={transaction.metadata}
                                xs={12}
                                md={7}
                            />

                            <Box width="100%" mb={2} />
                        </Grid>
                    </>
                }
            </CardContent>
        </Card>
    )
}
