import { useState, useEffect } from 'react'
import { Button, Grid } from '@mui/material'
import { Link } from 'react-router-dom'

import AddIcon from '@mui/icons-material/Add'
import { Card, CardContent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Filters from './Filters'
import AppFilters from '../../AppFilters'
import EmailInvoicesTable from './EmailInvoicesTable'
import { useCallback } from 'react'
import { toast } from 'react-toastify'
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import useAuth from "../../../hooks/useAuth"
import PageTitle from '../../Misc/PageTitle'
import UploadFileIcon from '@mui/icons-material/UploadFile'


export default function EmailInvoicesList() {
    const { t } = useTranslation()

    const { setAuth } = useAuth()
    const axiosPrivate = useAxiosPrivate()

    const [loading, setLoading] = useState(true)

    const [params, setParams] = useState({})

    const [data, setData] = useState([])

    const [meta, setMeta] = useState({
        total_pages: 1,
        current_page: 1
    })

    const handleOnSearch = values => {
        if (values === params) {
            return false
        }

        setLoading(true)
        setParams(values)
    }

    const handleOnPageChange = (e, page) => {
        setMeta({ ...meta, current_page: page })
        setParams({ ...params, page: page })
    }

    const retrieveData = useCallback(() => {
        axiosPrivate.get('/merchant/email-invoices/', { params: params })
            .then(({ data: { data, meta } }) => {
                setMeta(meta)
                setData(data)
            })
            .catch(({ response }) => {
                if (response?.status === 401) {
                    localStorage.removeItem('user')
                    return setAuth({})
                }

                toast.error(t('Something went wrong'))
            })
            .finally(() => setLoading(false))

    }, [params, t, setAuth, axiosPrivate])

    const handleNotification = (id) => {
        return axiosPrivate.post(`/merchant/email-invoices/${id}/send/`, {})
            .then(() => {
                setData(data.map(d => {
                    if (d.id === id) {
                        d.status_ = 'Sent'
                        d.sent_at = new Date().toUTCString()
                    }

                    return d
                }))

                toast.success(t('Your Email Invoice has been sent'))
            })
            .catch(({ response }) => {
                if (response?.status === 401) {
                    localStorage.removeItem('user')
                    return setAuth({})
                }

                toast.error(t('Something went wrong'))
            })
    }

    useEffect(() => {
        const loadData = () => {
            retrieveData()
        }

        loadData()
    }, [params, retrieveData])

    return (
        <>
            <Card>
                <CardContent>
                    <PageTitle title={t('Email invoices')} />

                    <Grid container spacing={2} mt={2} mb={2}>
                        <Grid item xs={12}>
                            <Button
                                component={Link}
                                to="create"
                                size="small"
                                variant="contained"
                                startIcon={<AddIcon />}
                                className="base__btn-action"
                            >
                                {t('New')}
                            </Button>

                            <Button
                                component={Link}
                                to="upload"
                                size="small"
                                variant="contained"
                                startIcon={<UploadFileIcon />}
                                color={'info'}
                            >
                                {t('Upload file')}
                            </Button>
                        </Grid>
                    </Grid>

                    <AppFilters Filters={<Filters handleOnSearch={handleOnSearch} />} />

                    <EmailInvoicesTable
                        data={data}
                        pages={meta.total_pages}
                        page={meta.current_page}
                        loading={loading}
                        handleOnPageChange={handleOnPageChange}
                        handleNotification={handleNotification}
                    />
                </CardContent>
            </Card>
        </>
    )
}
