import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import { useMediaQuery } from '@mui/material';

Logo.propTypes = {
    sx: PropTypes.object
};

export default function Logo({ sx }) {
    const hidden = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return hidden ? null : <Box
        component="img"
        src="https://cdn.payretailers.cl/images/payretailers/logo-payretailers-3.png"
        sx={{
            height: 64,
            ...sx
        }}
    />;
}
