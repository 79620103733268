import { useState, useRef, useEffect } from 'react'
import { Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material'
import { Formik, Form } from 'formik'
import TextfieldWrapper from '../../FormUI/Textfield/TextfieldWrapper'
import ButtonWrapper from '../../FormUI/ButtonWrapper/ButtonWrapper'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import SubmitWrapper from '../../FormUI/SubmitWrapper/SubmitWrapper'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import SelectWrapper from '../../FormUI/SelectWrapper/SelectWrapper'
import { currencies } from '../../../enums/PaymentLinks'
import GenericWrapper from "../../FormUI/GenericWrapper/GenericWrapper";
import { Base64FileField } from '../../FormUI/Base64FileField/Base64FileField'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import DeleteIcon from '@mui/icons-material/Delete'

export function AttachedFileList({ fileUrl, handleRemoveFile }) {
    const [fileName, setFileName] = useState('')

    useEffect(() => {
        const fileParts = fileUrl.split('/')

        setFileName(fileParts[fileParts.length - 1])
    }, [fileUrl])

    return <List>
        <ListItem secondaryAction={
            <IconButton edge="end" aria-label="delete" onClick={handleRemoveFile}>
                <DeleteIcon />
            </IconButton>
        }>
            <ListItemIcon>
                <AttachFileIcon />
            </ListItemIcon>
            <ListItemText primary={fileName} />
        </ListItem>
    </List>
}

export default function EmailInvoicesForm({
    isNew,
    handleSubmit,
    initialFormState,
    FORM_VALIDATION
}) {
    const { t } = useTranslation()
    // eslint-disable-next-line
    const [file, setFile] = useState(null);
    const ref = useRef(null)

    const navigate = useNavigate()

    const [initialValues, setInitialValues] = useState(initialFormState)

    const handleRemoveFile = () => {
        setInitialValues({
            ...initialValues, image_url: null
        })

        ref.current.setValues(initialValues)
    }



    return (
        <Formik
            innerRef={ref}
            initialValues={{
                ...initialValues
            }}
            enableReinitialize
            validationSchema={FORM_VALIDATION}
            onSubmit={handleSubmit}
        >
            {({ values }) => (
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h5'>{t('Email invoices')}</Typography>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextfieldWrapper
                                name="title"
                                label={t('Title')}
                                type="text"
                            />
                        </Grid>

                        <Box width="100%" />

                        <Grid item xs={12} md={8}>
                            <TextfieldWrapper
                                name="client_email"
                                label={t('Email address')}
                                type="email"
                            />
                        </Grid>

                        <Box width="100%" />

                        <Grid item xs={12} md={6}>
                            <TextfieldWrapper
                                name="client"
                                label={t('Client full name')}
                                type="text"
                            />
                        </Grid>

                        <Box width="100%" />

                        <Grid item xs={8} md={3}>
                            <TextfieldWrapper
                                name="amount"
                                label={t('Amount')}
                                type="number"
                            />
                        </Grid>

                        <Grid item xs={4} md={2}>
                            <SelectWrapper
                                name="currency"
                                label={t('Currency')}
                                options={currencies}
                                translate={false}
                            />
                        </Grid>

                        <Box width="100%" />

                        <Grid item xs={12}>
                            <TextfieldWrapper
                                name="details"
                                multiline
                                rows={2}
                                label={t('Details')}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            {file ? <img src={URL.createObjectURL(file)} alt={file.name} height='auto' style={{ maxWidth: 600 }}/> : initialFormState.image_url ? <img src={initialFormState.image_url} alt={t('PDF file')} height='auto' style={{ maxWidth: 600 }}/> : <></>}
                        </Grid> */}
                        <Grid item xs={12}>

                            {initialValues.image_url ? <AttachedFileList
                                fileUrl={initialValues.image_url}
                                handleRemoveFile={handleRemoveFile}
                            /> : <GenericWrapper
                                name="image_base64"
                                WrappedComponent={Base64FileField}
                                onChange={(e) => setFile(e.file)}
                            />}
                        </Grid>

                        <Box width="100%" />

                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2}>
                                <SubmitWrapper>{t('Save')}</SubmitWrapper>
                                <ButtonWrapper
                                    variant="text"
                                    onClick={() => navigate('/email-invoices')}
                                    startIcon={<CloseIcon />}>{t('Cancel')}</ButtonWrapper>
                            </Stack>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>

    )
}
