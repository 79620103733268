import { useEffect } from 'react';

/**
 * Buscar mejor opcion de realizar redirect en nueva ventana
 */
export default function Docs() {
    useEffect(() => {
        window.location.href = "https://developers.payretailers.cl";
    }, []);

    return <div></div>;
}
