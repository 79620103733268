import { useState } from 'react'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

import { Outlet } from "react-router-dom"

import Navbar from '../Header/Navbar'
import Sidebar from '../Sidebar/Sidebar'
import { Grid } from '@mui/material'


const drawerWidth = 250

export default function Page() {
    const [mobileOpen, setMobileOpen] = useState(false)

    const { i18n } = useTranslation()

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    const handleChangeLanguage = (lng) => {
        i18n.changeLanguage(lng)
        window.location.reload(false)
    }

    return (
        <Grid container spacing={2} sx={{
            marginBottom: '3rem'
        }}>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex' }}>
                    <Sidebar
                        handleDrawerToggle={handleDrawerToggle}
                        mobileOpen={mobileOpen}
                        drawerWidth={drawerWidth}
                    />

                    <Navbar
                        handleDrawerToggle={handleDrawerToggle}
                        mobileOpen={mobileOpen}
                        drawerWidth={drawerWidth}
                        handleChangeLanguage={handleChangeLanguage}
                    />

                    <main className="main-container">
                        <div className="top-brick" />
                        <Outlet />
                    </main>
                </Box>

                {/* <Fab
                    color="primary"
                    variant="extended" sx={{
                        position: 'fixed',
                        bottom: '30px',
                        right: '30px'
                    }}
                    component={NavLink}
                    to='/redirect'
                    target="_blank"
                    rel='noopener noreferrer'>
                    <HelpOutlineIcon sx={{ mr: 1 }} />
                    {t('Help')}
                </Fab> */}
            </Grid>
        </Grid>
    )
}
