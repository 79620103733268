import { useState, useEffect } from 'react'
import { Card, CardActions, CardContent, CardHeader } from '@mui/material'
import PageTitle from '../../Misc/PageTitle'
import CashoutsTable from './CashoutsTable'
import useIsMounted from '../../../hooks/useIsMounted'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { useTranslation } from 'react-i18next'
import useAuth from '../../../hooks/useAuth'
import BalanceModal from './BalanceModal'
import { toast } from 'react-toastify'
import BackdropWrapper from '../../BackdropWrapper/BackdropWrapper'
import Button from '@mui/material/Button'
import AppFilters from '../../AppFilters';
import Filters from './Filters';

export default function CashoutsBalance() {
  const { t } = useTranslation()

  const { auth, setAuth } = useAuth()

  const isMounted = useIsMounted()

  const axiosPrivate = useAxiosPrivate()

  const [open, setOpen] = useState(false)

  const [loading, setLoading] = useState(true)

  const [working, setWorking] = useState(false)

  const [loadingBalance, setLoadingBalance] = useState(true)

  const [params, setParams] = useState({})

  const [meta, setMeta] = useState({
    total_pages: 1,
    current_page: 1
  })

  const [data, setData] = useState([])

  const [balance, setBalance] = useState()

  const [amount, setAmount] = useState(0)

  const handleOnSearch = values => {
    setLoading(true)

    setParams(values)
  }

  const handleOnPageChange = (e, page) => {
    setLoading(true)

    setMeta({ ...meta, current_page: page })
    setParams({ ...params, page: page })
  }

  const handleOpen = () => {
    setAmount(0)
    setOpen(true)
  }

  const handleConfirm = () => {
    setWorking(true)

    axiosPrivate.post('/merchant/cashouts/', {
      amount: amount,
      conversion: 1,
      currency: auth?.user?.settlement_currency,
      details: 'Payins account >> Payouts account',
      original_amount: amount,
      original_currency: auth?.user?.settlement_currency
    })
      .then(({ data: { data } }) => data)
      .then((result) => {
        result.amount_balance = result.amount_balance_

        setData([
          result, ...data
        ])

        setBalance({
          ...balance, balance: result.amount_balance
        })

        setOpen(false)
        setAmount(0)
      })
      .catch(({ response }) => {
        if (response?.status === 401) {
          localStorage.removeItem('user')
          return setAuth({})
        }

        toast.error(t('Something went wrong'))
      })
      .finally(() => setWorking(false))
  }

  useEffect(() => {
    const retrieveData = () => {
      axiosPrivate.get('/merchant/cashouts/movements/', { params: params })
        .then(({ data: { data } }) => {
          if (isMounted()) {
            setData(data)
          }
        })
        .catch(({ response }) => {
          if (response?.status === 401) {
            localStorage.removeItem('user')
            return setAuth({})
          }

          toast.error(t('Something went wrong'))
        })
        .finally(() => setLoading(false))
    }

    const getBalance = () => {
      axiosPrivate.get('/merchant/cashouts/balance/')
        .then(({ data: { data } }) => {
          if (isMounted()) {
            setBalance(data)
          }
        })
        .catch(({ response }) => {
          if (response?.status === 401) {
            localStorage.removeItem('user')
            return setAuth({})
          }

          toast.error(t('Something went wrong'))
        })
        .finally(() => setLoadingBalance(false))
    }

    getBalance()
    retrieveData()
  }, [t, axiosPrivate, isMounted, setAuth, params])

  return (
    <>
      <Card>
        <CardContent>
          <PageTitle title={t('Cashouts')} />

          <Card sx={{ my: 2 }}>
            <CardHeader title={'Balance'} />

            <CardContent>
              {loadingBalance && (<BackdropWrapper open={loadingBalance} />)}

              {!loadingBalance && (
                <h1>{new Intl.NumberFormat('es-CL', { style: 'currency', currency: balance.currency }).format(balance.balance)}</h1>
              )}
            </CardContent>

            <CardActions sx={{ p: 2 }}>
              <Button
                variant="contained"
                onClick={handleOpen}
              >{t('Add funds')}</Button>
            </CardActions>
          </Card>

          <AppFilters Filters={<Filters handleOnSearch={handleOnSearch} />} />

          <BalanceModal
            amount={amount}
            setAmount={setAmount}
            currency={auth?.user?.settlement_currency}
            requesting={working}
            open={open}
            handleClose={() => setOpen(false)}
            handleConfirm={handleConfirm}
          />

          <CashoutsTable
            data={data}
            pages={meta.total_pages}
            page={meta.current_page}
            loading={loading}
            handleOnPageChange={handleOnPageChange}
          />
        </CardContent>
      </Card>
    </>
  )
}
