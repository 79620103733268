import { Outlet } from "react-router-dom";
import AlertDocuments from "../components/Misc/AlertDocuments";

import useAuth from "../hooks/useAuth";

export default function MainRouter() {
  const { auth } = useAuth();

  return !auth?.user?.has_documents_to_sign ? <Outlet /> : <AlertDocuments />;
}
