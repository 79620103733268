import { useEffect } from 'react'
import { Card, CardContent } from '@mui/material'
import { useState } from 'react'
import FormSkeletonWrapper from '../../SkeletonUI/FormSkeletonWrapper/FormSkeletonWrapper'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import PaymentLinksForm from './PaymentLinksForm'
import { toast } from 'react-toastify'
import { parseErrors, removeNullValues, replaceNullValues } from '../../../services/utils'
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import useAuth from "../../../hooks/useAuth"


export default function PaymentLinksEdit() {
   const { t } = useTranslation()

   const { setAuth } = useAuth()
   const axiosPrivate = useAxiosPrivate()

   const navigate = useNavigate()

   const { invoiceId } = useParams()

   const [loading, setLoading] = useState(true)

   const [paymentLink, setPaymentLink] = useState()

   const handleCancel = () => {
      navigate('/payment-links')
   }

   const handleSubmit = (values, { setFieldError }) => {
      values = removeNullValues(values)

      if (!values.image) {
         values.image = null
      }

      axiosPrivate.put(`/merchant/payment-links/${invoiceId}/`, values)
         .then(() => {
            toast.success(t('Your Payment link has been updated'))
            navigate('/payment-links')
         })
         .catch(({ response }) => {
            if (response?.status === 401) {
               localStorage.removeItem('user')
               return setAuth({})
            }

            const errors = parseErrors(response)

            if (typeof errors === 'object') {
               for (let i in errors) {
                  setFieldError(errors[i].field, t(errors[i].message), false)
               }
            } else if (typeof errors === 'string') {
               toast.error(t(errors))
            } else {
               toast.error(t('Something went wrong'))
            }
         })
   }

   const FORM_VALIDATION = Yup.object().shape({
      title: Yup.string()
         .required(t('This field is required'))
         .max(64, t('This field must contain a maximum of {{max}} characters', { max: 64 })),
      amount: Yup.number()
         .min(1, t('Price must be greater than or equal to {{min}}', { min: 1 }))
         .required(t('This field is required')),
      currency: Yup.string()
         .required(t('This field is required'))
         .test('len', t('This field must be a valid currency code'), val => val.length === 3),
      details: Yup.string()
         .max(512, t('This field must contain a maximum of {{max}} characters', { max: 512 })),
      image: Yup.string().nullable()
   })

   useEffect(() => {
      const getUser = () => {
         axiosPrivate.get(`/merchant/payment-links/${invoiceId}/`)
            .then(({ data: { data } }) => {
               if (!data.title) {
                  data.title = ''
               }

               setPaymentLink(replaceNullValues(data))
            })
            .catch(({ response }) => {
               if (response?.status === 401) {
                  localStorage.removeItem('user')
                  return setAuth({})
               }

               toast.error(t('Something went wrong'))
            })
            .finally(() => setLoading(false))
      }

      getUser()
   }, [invoiceId, t, axiosPrivate, setAuth])

   return (
      <>
         <Card>
            <CardContent>
               {
                  loading ? <FormSkeletonWrapper /> :
                     <PaymentLinksForm
                        isNew={false}
                        handleSubmit={handleSubmit}
                        handleCancel={handleCancel}
                        initialFormState={paymentLink}
                        FORM_VALIDATION={FORM_VALIDATION}
                     />
               }
            </CardContent>
         </Card>
      </>
   )
}
