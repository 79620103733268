import { useRef } from 'react'
import { Grid, Stack, Typography } from '@mui/material'
import { Formik, Form } from 'formik'
import TextfieldWrapper from '../../FormUI/Textfield/TextfieldWrapper'
import ButtonWrapper from '../../FormUI/ButtonWrapper/ButtonWrapper'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import SubmitWrapper from '../../FormUI/SubmitWrapper/SubmitWrapper'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import SelectWrapper from '../../FormUI/SelectWrapper/SelectWrapper'
import { currencies } from '../../../enums/PaymentLinks'

export default function PaymentLinksForm({
    isNew,
    handleSubmit,
    initialFormState,
    FORM_VALIDATION
}) {
    const { t } = useTranslation()

    const ref = useRef(null)

    const navigate = useNavigate()

    return (
        <Formik
            innerRef={ref}
            initialValues={{
                ...initialFormState
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={handleSubmit}
        >
            {({ values }) => (
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h5'>{t('Payment links')}</Typography>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextfieldWrapper
                                name="title"
                                label={t('Title')}
                                type="text"
                                inputProps={{
                                    maxLength: 64
                                }}
                            />
                        </Grid>

                        <Box width="100%" />

                        <Grid item xs={8} md={3}>
                            <TextfieldWrapper
                                name="amount"
                                label={t('Amount')}
                                type="number"
                            />
                        </Grid>

                        <Grid item xs={4} md={2}>
                            <SelectWrapper
                                name="currency"
                                label={t('Currency')}
                                options={currencies}
                                translate={false}
                            />
                        </Grid>

                        <Box width="100%" />

                        <Grid item xs={12}>
                            <TextfieldWrapper
                                name="details"
                                multiline
                                rows={2}
                                label={t('Details')}
                                inputProps={{
                                    maxLength: 1500
                                }}
                            />
                        </Grid>

                        {/* <Grid item xs={12}>
                            <GenericWrapper
                                name={"image"}
                                WrappedComponent={Base64FIleField}
                            />
                        </Grid>
                        <Box width="100%" /> */}

                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2}>
                                <SubmitWrapper sx={{
                                    width: {
                                        xs: '100%',
                                        md: 'auto'
                                    }
                                }}>{t('Save')}</SubmitWrapper>
                                <ButtonWrapper
                                    variant="text"
                                    onClick={() => navigate('/payment-links')}
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            md: 'auto'
                                        }
                                    }}
                                    startIcon={<CloseIcon />}>{t('Cancel')}</ButtonWrapper>
                            </Stack>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>

    )
}
