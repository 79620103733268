import EmailInvoicesForm from './EmailInvoicesForm'
import { Card, CardContent } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { getFormatedDate, parseErrors } from '../../../services/utils'
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"
import useAuth from "../../../hooks/useAuth"


export default function EmailInvoicesCreate() {
  const { t } = useTranslation()

  const { setAuth } = useAuth()
  const axiosPrivate = useAxiosPrivate()

  const navigate = useNavigate()

  const handleSubmit = (values, { setFieldError }) => {
    values.due_date = new Date(values.due_date)

    axiosPrivate.post('/merchant/email-invoices/', values)
      .then(() => {
        toast.success(t('Your Email Invoice has been created'));
        navigate('/email-invoices')
      })
      .catch(({ response }) => {
        if (response?.status === 401) {
          localStorage.removeItem('user')
          return setAuth({})
        }

        const errors = parseErrors(response)

        if (typeof errors === 'object') {
          for (let i in errors) {
            setFieldError(errors[i].field, t(errors[i].message), false)
          }
        } else if (typeof errors === 'string') {
          toast.error(t(errors))
        } else {
          toast.error(t('Something went wrong'))
        }
      })
  }

  const handleCancel = () => {
    navigate('/email-invoices')
  }

  const today = new Date()

  const due_date = () => {
    const due_date = new Date((new Date()).setDate(today.getDate() + 60))
    return getFormatedDate(due_date)
  }

  const max_date = () => {
    const max_date = new Date((new Date()).setDate(today.getDate() + 365))
    return getFormatedDate(max_date)
  }

  const initialFormState = {
    title: '',
    subject: '',
    due_date: due_date(),
    currency: 'CLP',
    amount: '',
    client_email: '',
    client: '',
    image_url: null
  }

  const FORM_VALIDATION = Yup.object().shape({
    due_date: Yup.date(t('Due date must be a valid date'))
      .required(t('This field is required'))
      .min(new Date(), t('Due date must be after today'))
      .max(max_date(), t('Due date cannot exceed {{days}} days', { days: 365 })),
    title: Yup.string()
      .required(t('This field is required'))
      .max(64, t('This field must contain a maximum of {{max}} characters', { max: 32 })),
    currency: Yup.string()
      .required(t('This field is required'))
      .test('len', t('This field must be a valid currency code'), val => val.length === 3),
    details: Yup.string()
      .max(512, t('This field must contain a maximum of {{max}} characters', { max: 512 })),
    client_email: Yup.string()
      .email(t('You must enter a valid email address'))
      .required(t('Email address is required')),
    client: Yup.string()
      .required(t('This field is required')),
    amount: Yup.number()
      .min(1, t('Price must be greater than or equal to {{min}}', { min: 1 }))
      .required(t('This field is required')),
  })

  return (
    <>
      <Card>
        <CardContent>
          <EmailInvoicesForm
            isNew={true}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            initialFormState={initialFormState}
            FORM_VALIDATION={FORM_VALIDATION}
          />
        </CardContent>
      </Card>
    </>
  )
}
