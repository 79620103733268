import { Typography } from "@mui/material"
import Link from '@mui/material/Link'


export default function Copyright(props) {
    return (
        <Typography
            variant="body2"
            component='div'
            color="text.secondary"
            align="center"
            {...props}
        >
            <Typography
                style={{
                    fontSize: '90%'
                }}
            >
                2023 ©
                <Link color="inherit" href="https://payretailers.cl/" target="_blank" rel='noopener'>
                    PayRetailers
                </Link>
            </Typography>

            <Typography
                style={{
                    fontSize: '90%'
                }}
            >All rights reserved</Typography>

        </Typography>
    )
}
