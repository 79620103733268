import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import { Outlet } from 'react-router-dom'
import { Box } from '@mui/system'


// const SignUpButton = styled(Button)({
//     boxShadow: 'none',
//     textTransform: 'none',
//     fontSize: 16,
//     padding: '6px 12px',
//     border: '2px solid',
//     lineHeight: 1.5,
//     backgroundColor: 'transparent',
//     color: 'white',
//     borderColor: 'white',
//     '&:hover': {
//         border: '2px solid',
//         borderColor: '#AAA',
//         boxShadow: 'none',
//     },
//     '&:active': {
//         boxShadow: 'none',
//         borderColor: '#005cbf',
//     },
//     '&:focus': {
//         boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
//     },
// })

const theme = createTheme()

export default function LoginScreen() {
    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: '100vh' }} >
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    pt={4}
                    sx={{
                        background: 'linear-gradient(90deg,#001BA9 16%,#0027f9 99%)',
                        textAlign: 'center'
                    }}
                >
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            display: { xs: 'none', md: 'block' },
                            margin: '40vh auto'
                        }}
                    >
                        <Grid item>
                            <a href="https://www.payretailers.cl" target='_blank' rel='noreferrer noopener'><Box
                                component="img"
                                sx={{
                                    maxWidth: {
                                        xs: '60%',
                                        lg: '50%',
                                        xl: '40%'
                                    }
                                }}
                                alt="PayRetailers"
                                src="https://cdn.payretailers.cl/images/payretailers/logo-payretailers-3.png"
                            /></a>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Outlet />
                </Grid>

            </Grid>
        </ThemeProvider>
    )
}