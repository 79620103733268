import { Typography } from "@mui/material";
import BackButton from "./BackButton";

export default function PageTitle({ title }) {
  return (
    <Typography
      variant="h4"
      fontFamily='"Roboto","Helvetica","Arial",sans-serif'
      mb={4}
      sx={{
        mt: {
          xs: "0px",
          md: "1rem",
        },
      }}
    >
      <BackButton />
      {title}
    </Typography>
  );
}
