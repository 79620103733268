import { useState } from 'react'
import { Alert, Button, Card, CardContent, Link, Stack, Typography } from '@mui/material'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import PageTitle from '../../Misc/PageTitle'
import { styled } from '@mui/material/styles'
import { LoadingButton } from '@mui/lab'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import ClearIcon from '@mui/icons-material/Clear'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useIsMounted from '../../../hooks/useIsMounted'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import EmailInvoicesUploadTable from './EmailInvoicesUploadTable'
import AttachFileIcon from '@mui/icons-material/AttachFile'


const Input = styled('input')({
  display: 'none',
})

export default function EmailInvoicesUpload() {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const axiosPrivate = useAxiosPrivate()

  const isMounted = useIsMounted()

  const [loading, setLoading] = useState(false)

  const [file, setFile] = useState()

  const [data, setData] = useState([])

  const handleChange = (event) => {
    setFile(event.target.files[0])
  }

  const handleUploadFile = () => {
    setLoading(true)

    axiosPrivate.post('/merchant/email-invoices/', file, {
      headers: {
        'Content-Disposition': 'inline; filename="MiArchivo"',
        'content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }
    })
      .then(({ data: { data } }) => {
        setData(data)
        handleResetFile()
        toast.success(t('Your Email Invoice has been sent'))
      })
      .catch(({ response }) => {
        if (isMounted()) {
          if (response?.status === 401) {
            handleUploadFile()
          }

          toast.error(t('Something went wrong. Please check the uploaded information in the file'))
        }
      })
      .finally(() => isMounted() && setLoading(false))
  }

  const handleResetFile = () => {
    setFile(null)
  }

  return (
    <Card>
      <CardContent>
        <PageTitle title={t('Email invoices upload')} />

        <Alert variant='outlined' severity="info" sx={{
          my: 2
        }}>
          <Typography>
            <Trans i18nKey="paymentLinkUpload1">
              In this section, you will be able to generate Email Invoice Charges in bulk, by uploading an Excel file.
            </Trans>
          </Typography>

          <Typography sx={{
            mt: .7
          }}>
            <Trans i18nKey="paymentLinkUpload2">
              Using our template for massive charges, you can make up to 100 electronic payments simultaneously.
            </Trans>
          </Typography>
          <Typography sx={{
            mt: .7,
            color: "red"
          }}>
            <Trans i18nKey="paymentLinkUpload3">
              IMPORTANT: Do not alter the template nor the column order, otherwise the system will not be able to read the file.
            </Trans>
          </Typography>
          <Typography sx={{
            mt: .7
          }}>
            {t('You can download the template in the following')}
            &nbsp;<Link
              href={`https://cdn.payretailers.cl/docs/templates/${t("template-mail-charge")}-v1.1.0.xlsx`}
              target='_blank'
              rel='noopener noreferrer'
              sx={{
                color: '#ff0054',
                textDecoration: 'none',
                fontWeight: 500
              }}
            >{t('link')}</Link> <small>(v1.0.2)</small>.
          </Typography>
        </Alert>

        {!file && (<label htmlFor="email-invoices-file">
          <Input
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
            onChange={handleChange}
            type="file"
            id="email-invoices-file"
          />
          <Button variant="contained" component="span" fullWidth>
            {t('Select file')}
          </Button>
        </label>)}

        {file && (
          <Stack direction="row" spacing={2} alignItems="center">
            <AttachFileIcon />

            <Typography variant="body2" color="text.secondary" alignItems="center" component='div'>
              {t('File name')}: {file.name} ({Math.round(file.size / 1000)}KB)
            </Typography>
          </Stack>
        )}

        <Stack my={4} direction="row" spacing={2} justifyContent="flex-end">
          {!!file && (
            <>
              <LoadingButton
                variant="contained"
                loading={loading}
                onClick={handleUploadFile}
                loadingPosition="start"
                startIcon={<UploadFileIcon />}
                size="small"
                disabled={loading}
                sx={{
                  width: {
                    xs: '100%',
                    md: 'auto'
                  }
                }}
              >
                {t('Upload file')}
              </LoadingButton>

              <Button
                variant="outlined"
                color="error"
                onClick={handleResetFile}
                size="small"
                sx={{
                  mx: {
                    md: 2
                  },
                  my: 1,
                  width: {
                    xs: '100%',
                    md: 'auto'
                  }
                }}
                startIcon={<ClearIcon />}>{t('Remove file')}</Button>
            </>
          )}

          <Button
            variant="text"
            onClick={() => navigate('/email-invoices')}
            startIcon={<CloseIcon />}
            size="small"
            sx={{
              mx: {
                md: 2
              },
              my: 1,
              width: {
                xs: '100%',
                md: 'auto'
              }
            }}
          >{t('Go back')}</Button>
        </Stack>
      </CardContent>

      {
        data.length ? (<CardContent>
          <EmailInvoicesUploadTable data={data} />
        </CardContent>) : ''
      }
    </Card >
  )
}
