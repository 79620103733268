import { Typography } from '@mui/material'

export default function CurrencyValue({
    amount,
    currency,
    text = '',
    ...otherProps
}) {
    const _sx = {
        color: amount < 0 ? 'red' : 'inherit',
        fontWeight: 500
    }

    return (
        <Typography noWrap sx={_sx} {...otherProps}>
            {text} {new Intl.NumberFormat('es-CL', { style: 'currency', currency: currency }).format(amount)}
        </Typography>
    )
}
