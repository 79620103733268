import { useState } from "react";
import { Grid, Box, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Copyright from "./Copyright";
import Button from "@mui/material/Button";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextfieldWrapper from "../FormUI/Textfield/TextfieldWrapper";
import api from "../../api";
import useAuth from "../../hooks/useAuth";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Isotipo from './Isotipo'
import Pagetitle from './Pagetitle'

const SignInButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '2px solid',
  lineHeight: 1.5,
  backgroundColor: '#0027f9',
  color: 'white',
  borderColor: 'white',
  '&:hover': {
      backgroundColor: '#001BA9',
      boxShadow: 'none',
  },
  '&:active': {
      boxShadow: 'none',
      borderColor: '#005cbf',
  }
})

const TextButton = styled(Button)({
  textTransform: "none",
});

export default function LoginForm() {
  const { t } = useTranslation();

  const { setAuth } = useAuth();

  const [authenticating, setAuthenticating] = useState(false);

  const login = (data) => {
    setAuthenticating(true);

    api
      .post("/merchant/login/", data, {
        headers: { "Content-Type": "application/json" },
      })
      .then(({ data: { data } }) => {
        const {
          refresh,
          access,
          roles,
          settlement_currency,
          merchant_status,
          cashouts_is_enabled,
          has_documents_to_sign,
        } = data;

        localStorage.setItem(
          "user",
          JSON.stringify({
            refresh,
            access,
            roles,
            settlement_currency,
            merchant_status,
            cashouts_is_enabled,
            has_documents_to_sign,
          })
        );

        setAuth({
          user: {
            refresh,
            access,
            roles,
            settlement_currency,
            merchant_status,
            cashouts_is_enabled,
            has_documents_to_sign,
          },
        });
      })
      .catch(() => {
        toast.error(t("Invalid credentials"));
      })
      .finally(() => setAuthenticating(false));
  };

  const initialFormState = {
    email: "",
    password: "",
  };

  const FORM_VALIDATION = Yup.object().shape({
    email: Yup.string()
      .email(t("You must enter a valid email address"))
      .required(t("Email address is required")),
    password: Yup.string().required(t("Password is required")),
  });

    return (
        <>
            <Box
                sx={{
                    my: 8,
                    mx: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Isotipo />

                <Pagetitle title={t('Welcome')} />

                <Formik
                    initialValues={{
                        ...initialFormState
                    }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={login}
                >
                    <Form sx={{ mt: 1 }}>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={12}>
                                <TextfieldWrapper
                                    name="email"
                                    fullWidth
                                    label={t('Email address')}
                                    type="email"
                                    autoFocus
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextfieldWrapper
                                    name="password"
                                    fullWidth
                                    label={t('Password')}
                                    type="password"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Stack direction="row" spacing={2}>
                                    <SignInButton
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                        disabled={authenticating}
                                    >
                                        {t('Sign In')}
                                    </SignInButton>
                                </Stack>

                                <Grid container>
                                    <Grid item xs align="center">
                                        <TextButton to="/auth/password" component={Link}>
                                            {t('Did you forget your password?')}
                                        </TextButton>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Copyright sx={{ mt: 5 }} />
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
            </Box>
        </>
    )
}
